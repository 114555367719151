@import 'nprogress/nprogress.css';

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 14px;
  background-color: var(--color-bg-1);
}

.chart-wrapper {
  .bizcharts-tooltip {
    background: linear-gradient(
      304.17deg,
      rgb(253 254 255 / 60%) -6.04%,
      rgb(244 247 252 / 60%) 85.2%
    ) !important;
    border-radius: 6px;
    backdrop-filter: blur(10px);
    padding: 8px !important;
    width: 180px !important;
    opacity: 1 !important;
  }
}

body[arco-theme='dark'] {
  .chart-wrapper {
    .bizcharts-tooltip {
      background: linear-gradient(
        304.17deg,
        rgba(90, 92, 95, 60%) -6.04%,
        rgba(87, 87, 87, 60%) 85.2%
      ) !important;
      backdrop-filter: blur(10px);
      border-radius: 6px;
      box-shadow: none !important;
    }
  }
}

body{

  .actions {
    padding: 12px 40px;
    background-color: var(--color-bg-2);
    display: flex;
    flex-direction: row-reverse;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 -3px 12px rgb(0 0 0 / 10%);
  }

}
body {
  .ant-btn-primary {
    background-color: rgb(var(--primary-6));
  }

  .ant-form-item label {
    color: var(--color-text-2);
  }

  .ant-form-item-label > label::after {
    content: ' ' !important;
  }

  .ant-input-affix-wrapper {
    border: 1px solid transparent !important;
    background: #f2f3f5 !important;

    input {
      background: #f2f3f5 !important;
    }
  }

  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
  }

  .ant-pro-table .ant-pro-table-search::after {
    background: #f2f3f5;
    width: 100% !important;
    height: 1px;
    display: block;
  }

  .ant-pro-table .ant-form-item-label {
    text-align: left !important;
  }

  .ant-pro-table > .ant-pro-card {
    padding: 0 !important;
  }

  .ant-pro-table .ant-pro-table-search {
    margin-bottom: 0 !important;
  }

  .ant-pro-table > .ant-pro-card > .ant-pro-card-body {
    padding: 0;
  }

  span.link{
    color: rgb(var(--primary-6));
    &:hover {
      cursor: pointer;
    }
  }
  span.link.sp{
    &::after{
      color: #aeb6c6;
      content:" | ";
      padding:0 4px;
    }
  }
}

.n-container{
  padding-left:30px;
}